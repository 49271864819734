import * as React from "react"
import { Link } from "gatsby";
import { navigate } from 'gatsby-link';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ContactUsImage from '../svg/Medical-BG9.svg';

const ThankYou = () => {

    return(
        <Layout>
        <SEO title="Contact Us"/>
        <main>
           <div className="py-24 bg-blue-900 sm:py-32">
           <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
               <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-white text-center sm:text-5xl sm:leading-none lg:text-6xl">Get in touch</h1>
           </div>
           </div>

           <div className="relative bg-white">
           
           <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
               <div className="lg:pr-8">
                    <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                        <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                        Thank you for contacting us.
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                        We will get back to you withing 24 hours. We look forward to speaking with you. 
                        </p>
                        <div className="text-left sm:col-span-2 mt-10">
                            <Link to="/" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grape-500">
                            Go Back Home
                            </Link>
                        </div>
                    </div>
               </div>
           </div>
           </div>
       </main>
   </Layout>
    )
}

export default ThankYou;